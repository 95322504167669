<template>
  <div>
    <h2 class="mb-4">Belege Import</h2>

    <div class="mb-4">
      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="insertData"
      >
        Belege importieren
      </button>

      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="updateData"
      >
        Update Belege Tabelle
      </button>
    </div>

    <dashboard-element>
      <dashboard-head title="Belege" link="/belege" />
      <dashboard-table class="p-3">
        <dashboard-card
          v-for="beleg in belege"
          :key="beleg.auftragsnummer"
          :title="`${beleg.auftragsnummer}`"
          :content="`${beleg.customernumber}`"
          :link="`/beleg/${beleg.auftragsnummer}`"
          :date="beleg.date"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "@/components/dashboard/DashboardElement.vue";
import DashboardHead from "@/components/dashboard/DashboardHead.vue";
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import DashboardTable from "@/components/dashboard/DashboardTable.vue";
import { supabase } from "../../plugins/supabase";

export const GET_MY_DATA = gql`
  query Belege {
    Belege(where: { Belegart: { _eq: "AB" }, Status: { _eq: "O" } }) {
      KundenNr
      BelegNr
      Belegart
      SummeNetto
      Status
      Datum
      IhrBeleg
    }
  }
`;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  data() {
    return {
      belege: []
    };
  },
  apollo: {
    Belege: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async updateData() {
      this.Belege.map(kunde =>
        supabase.from("belege").upsert([
          {
            customernumber: kunde.KundenNr,
            company: kunde.Firma
          }
        ])
      );
    },
    async insertData() {
      const neue_belege = [];
      this.Belege.map(belege =>
        neue_belege.push({
          customernumber: belege.KundenNr,
          auftragsnummer: belege.BelegNr,
          summenetto: parseFloat(belege.SummeNetto),
          status: 0,
          date: belege.Datum,
          ordernumber: belege.IhrBeleg
        })
      );

      await supabase.from("auftraege").insert(neue_belege);
    },
    async loadData() {
      let { data: auftraege } = await supabase
        .from("auftraege")
        .select("*")
        .range(0, 9);

      this.belege = auftraege;
    }
  }
};
</script>
